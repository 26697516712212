import React, { useState, useEffect } from 'react';
import superApi from '../../Api/Api';
import { Table, Button } from 'reactstrap';
import { Link, Switch, Route } from 'react-router-dom';
import Loading from '../../Components/Loading/Loading';
import Category from './Category/Category';
import CategoryForm from '../../Forms/CategoryForm';

const Categories = (props) => {
    const [tableData, setTableData] = useState([])
    const [loading, setLoading] =useState(false);
    const [showForm, setShowForm] = useState(false);
    const [reload, setReload] = useState(true)

    useEffect(() => {
        setShowForm(false)
        superApi.get('/category').then((response) => {
            setTableData(response.data._items);
        })
    }, [reload])

    const TABLEDATA = tableData.map(item => {
        return (
            <tr key={item._id}>
                <td><Link to={props.match.url + "/" + item._id}>{item.name}</Link></td>
                <td>{item.slogan}</td>
                <td style={{backgroundColor: item.color}}></td>
            </tr>
        )
    })

    return (
        <Switch>
            <Route path={props.match.url + "/:category"} render={(props) => <Category {...props} reload={() => setReload(!reload)}/>} />
        <React.Fragment>
        <div className="heading-wrapper d-flex justify-content-between align-items-center">
                    <p className="display-4">Categories</p>
                    <Button onClick={() => setShowForm(true)} color="outline-info">Add</Button>

                </div>
                <div className="add-form-wrapper">
                    <CategoryForm reload={() => setReload(!reload)} hidden={!showForm} />

                </div>
        <Table>
            <thead>
                <tr>
                    <th>short-code</th>
                    <th>Display Name</th>
                    <th>Color</th>
                </tr>
            </thead>
            <tbody>
                {TABLEDATA}
            </tbody>


        </Table>
        </React.Fragment>
        </Switch>
     );
}

export default Categories;
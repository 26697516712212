import React, { useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { Button, Col } from 'reactstrap';
import superApi from '../Api/Api';
import { SketchPicker } from 'react-color';



const MyForm = (props) => {


    const submitHandler = (form) => {
        if (props.existing) {
            superApi.patch('category' + '/' + props.existing._id, form).then(response => {
                console.log(response)
                props.reload()
                props.history.goBack()
            })
        } else {
        form.name = form.slogan.toLowerCase().replace(/ /g, '').replace(/[^a-z0-9]/g, '')
        superApi.post('category', form).then((response) => { console.log(response); props.reload() })
        .catch(error => {
            if (error.response) {
                console.log(error.response)
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                if(error.response.status == 422) {
                    if(error.response.data._issues.name !== undefined) {
                        alert('Category already exists!')
                    }
                    else {
                        alert('Some error occurred, slack me')
                    }
                }
                
              } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                alert('Some error occurred, slack me')
              } else {
                // Something happened in setting up the request that triggered an Error
                alert('Some error occurred, slack me')
              }
            //   alert('Some error occurred, slack me')
        })
    }
    
    }


    if (props.hidden) {
        return null;
    }
    return (
        
        <Formik
            initialValues={{
                
                slogan: props.existing ? props.existing.slogan : '',
                color: props.existing ? props.existing.color : '',
                
            }}
            validate={values => {
                const errors = {};
               
                if (!values.color || values.color === "") {
                    errors.color = 'No color chosen!'
                }
                
                return errors;
            }}
            onSubmit={(values, { setSubmitting }) => {
                submitHandler(values);
                setSubmitting(false);
            }}
        >
            {({ isSubmitting, values, setFieldValue }) => (
                <Col md="6">
                {console.log(values['color'])}
                    <p className="lead">{props.title ? props.title : 'Add New'}</p>
                    <Form className="add-form">
                   
                        
                        {/* <div className="form-group">
                            <Field className="form-control" as="input" name="name" placeholder="Short-code" />
                            <ErrorMessage name="title" component="div" />
                        </div> */}
                        <div className="form-group">
                            <Field className="form-control" as="input" name="slogan" placeholder="Display Name"/>
                            <ErrorMessage name="description" component="div" />
                        </div>
                       
                        <SketchPicker name={"color"} onChange={(e) => setFieldValue('color', e.hex)} color={values['color']} />
                        <ErrorMessage name="color" component="div" />
                        

                        <Button disabled={isSubmitting} color="info" type="submit">Submit</Button>
                    </Form>
                </Col>
            )}
        </Formik>

    );
}

export default MyForm;
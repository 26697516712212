import React,{useEffect, useContext} from 'react';
import { Redirect } from 'react-router-dom';
import { AuthContext } from '../Context/AuthContext';

const Aux = (props) => {

    const auth = useContext(AuthContext);

    useEffect(() => {
        if(window.localStorage.getItem('expired')) {
            auth.setAuthenticated(false);
        }
    })

    if(auth.authenticated) {
        return props.children;
    } else {
        return <Redirect to="/login" />
        // console.log('auth false' + auth.authenticated)
    }
    
}
export default Aux;
import React from 'react';
import AuthContext from './Context/AuthContext';
import { Route, Switch } from 'react-router-dom';
import {BrowserRouter} from 'react-router-dom';
import Layout from './Layout/Layout';
import {Login} from './Auth/Auth';

function App() {


  
  return (

    <BrowserRouter>
      <AuthContext>

        <Switch>
        {/* <Route path="/login" exact render={(props) => <Login {...props} auth={authForm}/>}/> */}
        <Route path="/login" exact render={() => <Login />} /> 
        <Route path="/" render={() => <Layout />} />
        
        </Switch>
        </AuthContext>
      </BrowserRouter>

  );
}

export default App;

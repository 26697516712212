import React, { useContext } from 'react';
import {LoginForm} from '../Forms/Forms';
import './Auth.css';
import { AuthContext } from '../Context/AuthContext';
import superApi from '../Api/Api';

const Login = (props) => {

    const auth = useContext(AuthContext);

    const loginSubmit = (model) => {
        window.localStorage.removeItem('expired');
        superApi.post('/super', {}, { auth: { username: model.username, password: model.password } })
            .then((response) => {
                auth.setAuthBody(response.data.token)
                auth.setAuthenticated(true);
                window.localStorage.setItem('authenticated', true);
                
                window.location.href = '/';

            })
            .catch((error) => {
                // Login Failed
                if(error.response.status == 401) {

                    // window.localStorage.setItem('authenticated', false);
                    // window.localStorage.setItem('authBody', null);
                    // auth.setAuthenticated(false);
                    alert('Login Failed');
                }
                
            });

    }

    return ( 
        <div className="Auth">
        <LoginForm submitHandler={loginSubmit} />
        </div>
     );
}
 
export {Login};
import React, { useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { Button, Col } from 'reactstrap';
import DatePicker from "react-datepicker";
import superApi from '../Api/Api';
// import { Multiselect } from 'react-widgets'
import 'react-widgets/dist/css/react-widgets.css';

const MyForm = (props) => {
    const [startDateValue, setStartDateValue] = useState(null)


    const submitHandler = (form) => {
        let _tasks = props.existingTasks.map(task => task)
        form.tasks = _tasks
        console.log(form);
        superApi.post('template', form).then((response) => { 
            console.log(response) 
            props.setReload(!props.reload)
            
            }
        ).catch(error => {
            console.log(error.response)
            if(error.response.status == 422) {
                console.log('template' + '/' + props.template._id)
                    superApi.patch('template' + '/' + props.template._id, form).then(response => {
                        console.log(response)
                        props.setReload(!props.reload)
                    })
            
        }
    })
}

    // const TASKOPTIONS = props.tasks.map(task => {
    //     return (
    //         task
    //     )
    // })


    if (props.hidden) {
        return null;
    }
    return (
        <Formik
            initialValues={{
                name: props.template != null ? props.template.name : '',
                default: props.template != null ? props.template.default : false,
                tasks: props.template != null ? props.template.tasks : [],
                delay: props.template != null ? props.template.delay : 0,
            }}
            validate={values => {
                const errors = {};
                // if (!values.username) {
                //     errors.username = 'Please enter something';
                // }
                if(values.delay%7 > 0) {
                    errors.delay = 'Please enter valid number multiple of 7'
                }
                return errors;
            }}
            onSubmit={(values, { setSubmitting }) => {
                submitHandler(values);
                setSubmitting(false);
            }}
        >
            {({ isSubmitting, values, setFieldValue }) => (
                <Col md="12">
                    {/* <p className="lead">{props.title || "Add New"}</p> */}
                    <Form className="add-form">
                        <div className="form-group">
                            <label htmlFor="name">Template Name</label>
                            <Field className="form-control" type="text" name="name" placeholder="Template Name" />
                            <ErrorMessage name="name" component="div" />
                        </div>
                        <div className="form-group">
                            <label htmlFor="delay">Delay Days</label>
                            <Field className="form-control" type="number" name="delay" />
                            <ErrorMessage name="delay" component="div" />
                        </div>
                        {/* <Multiselect data={TASKOPTIONS} textField={'title'} valueField={'_id'} defaultValue={props.existingTasks} dropUp onChange={e => setFieldValue("tasks", e)} /> */}

                        <Button disabled={isSubmitting} color="info" type="submit">Submit</Button>
                    </Form>
                </Col>
            )}
        </Formik>

    );
}

export default MyForm;
import React from 'react';
import { Button, Col } from 'reactstrap';
import './Home.css'

const Home = (props) => {
    return (
        <div className="title">
            <p className="display-4"></p>
        </div>
    )
}
 
export default Home;
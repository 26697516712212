import React, { useEffect, useState } from 'react';
import { Link, Route, Switch } from 'react-router-dom';
import superApi from '../../Api/Api';
import { Button } from 'reactstrap';
import Company from './Company/Company';
import CompanyForm from '../../Forms/CompanyForm';
import Loader from '../../HOC/Loader';
import DataTable from 'react-data-table-component'

const Companies = (props) => {

    const [tableData, setTableData] = useState([])
    const [showForm, setShowForm] = useState(false);
    const [loading, setLoading] = useState(true);
    const [reload, setReload] = useState(true);

    useEffect(() => {
        setShowForm(false)
        superApi.get('/company').then((response) => {
            setTableData(response.data._items);
            setLoading(false);
        })
    }, [reload])

   
    const columns = [
        {
            name: 'Company',
            selector: 'company',
            sortable: true,
            cell: row => <Link to={props.match.url + "/" + row._id}>{row.company}</Link>
        },
        {
            name: 'Slug',
            selector: 'slug',
            sortable: true,
            
        },
        {
            name: 'Start Date',
            selector: 'startdate',
            sortable: true,
        },

    ];
    return (
        <Switch>
            <Route path={props.match.url + "/:company"} render={(props) => <Company showForm={showForm} setShowForm={setShowForm} reload={reload} setReload={setReload} {...props} />} />

            <Loader loading={loading}>
                <div className="heading-wrapper d-flex justify-content-between align-items-center">
                    <p className="display-4">Companies</p>
                    <Button onClick={() => setShowForm(true)} color="outline-info">Add</Button>

                </div>
                <div className="add-form-wrapper">
                    <CompanyForm reload={reload} setReload={setReload} hidden={!showForm} />

                </div>
                <DataTable

                    columns={columns}
                    data={tableData}
                    defaultSortField="title"
                    keyField="_id"
                    // onRowClicked={(row) => rowClickHandler(row)}
                    pagination={true}
                    noHeader
                />
            </Loader>

        </Switch>

    );
}

export default Companies;
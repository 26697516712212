import React, { useEffect } from 'react';
import superApi from '../../Api/Api'

const Leaderboard = (props) => {

    useEffect(() => {
        superApi.get('api/leaderboard/websoftware')
        .then(response => console.log(response.data))
    },[])

    return ( 
        <div>Leaderboard</div>
     );
}
 
export default Leaderboard;
import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { Button, Col } from 'reactstrap';
import DatePicker from "react-datepicker";
import superApi from '../Api/Api';
import { Multiselect } from 'react-widgets'
import 'react-widgets/dist/css/react-widgets.css';

const MyForm = (props) => {

    const submitHandler = (form) => {
        console.log(form);
        props.setLoading(true)
        superApi.post('apply', form).then((response) => { 
            console.log(response) 
            props.setLoading(false)
            })
    }

    if (props.hidden) {
        return null;
    }

    const TEMPLATEOPTIONS = props.templates.map(template => {
        return (
            <option key={template._id} value={template._id}>{template.name}</option>
        )
    })

    return (
        <Formik
            initialValues={{
                template: '',
                company: props.company
            }}
            validate={values => {
                const errors = {};
                // if (!values.username) {
                //     errors.username = 'Please enter something';
                // }
                return errors;
            }}
            onSubmit={(values, { setSubmitting }) => {
                submitHandler(values);
                setSubmitting(false);
            }}
        >
            {({ isSubmitting, values, setFieldValue }) => (
                <Col md="6">
                    <p className="lead">{props.title || "Add New"}</p>
                    <Form className="add-form">
                    <div className="form-group">
                            <Field className="form-control" as="select" name="template">
                                <option>-- Select Template --</option>
                                {TEMPLATEOPTIONS}
                            </Field>
                            <ErrorMessage name="category" component="div" />
                        </div>
                        

                        <Button disabled={isSubmitting} color="info" type="submit">Submit</Button>
                    </Form>
                </Col>
            )}
        </Formik>

    );
}
 
export default MyForm;
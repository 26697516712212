import React, { useEffect, useState } from 'react';
import superApi from '../../../Api/Api';
import { Col } from 'reactstrap';
import ActivityForm from '../../../Forms/ActivityForm';
import { Button } from 'reactstrap';

const Activity = (props) => {
    const activityId = props.match.params.activity
    const [activity, setActivity] = useState({})
    const [showForm, setShowForm] = useState(false);
    const [categories, setCategories] = useState([]);

    useEffect(() => {
        
        superApi.get('/task/' + activityId).then((response) => {
            console.log(response.data)
            setActivity(response.data);

        }).then(response => {
            superApi.get('/category').then((response) => {
                setCategories(response.data._items);
                
            })
        })
    }, [])
    
    return ( 
        <Col md="12">
            
            <div className="heading-wrapper">
            <p className="display-4">{activity.title}</p>
                    <Button onClick={() => setShowForm(true)} color="outline-primary">Edit</Button>

                </div>
        
        <p className="lead">{activity.category}</p>
        <p className="">{activity.description}</p>
        <div className="add-form-wrapper">
                    <ActivityForm {...props} reload={props.reload} setReload={props.setReload} types={['daily', 'challenge', 'normal', 'weekly']} title="Edit" existing={activity} categories={categories} hidden={!showForm} />

                </div>
        </Col>
     );
}
 
export default Activity;
import React, { useState, useEffect } from 'react';
import superApi from '../../Api/Api';
import { Link, Route, Switch } from 'react-router-dom';
import { TabContent, TabPane, Nav, NavItem, NavLink, Button } from 'reactstrap';
import Activity from './Activity/Activity';
// import ActivityTable from './ActivityTable/ActivityTable';
import ActivityTable from '../../Components/ActivityTable/ActivityTable';
import Templates from './Templates/Templates';
import classnames from 'classnames';
import Loading from '../../Components/Loading/Loading';
import ActivityForm from '../../Forms/ActivityForm';
import Template from './Templates/Template/Template';
import Loader from '../../HOC/Aux';

const Activities = (props) => {
    const [activeTab, setActiveTab] = useState('activities');
    const [tableData, setTableData] = useState([])
    const [loading, setLoading] =useState(true);
    const [showForm, setShowForm] = useState(false);
    const [categories, setCategories] = useState([])
    const [reload, setReload] = useState(true);

    const toggleTab = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    }
    useEffect(() => {
        setShowForm(false)
        superApi.get('/task').then((response) => {
            setTableData(response.data._items);

        }).then(() => {

            superApi.get('/category').then((response) => {
                setCategories(response.data._items);
                setLoading(false);
            })
        })
    }, [reload])

    const TABLEDATA = tableData.map(item => {
        const _cat = categories.filter((cat) => {
            return cat.name === item.category ? cat.name : null
        })
        return (
            <tr key={item._id}>
                <td><Link to={props.match.url + "/" + item._id}>{item.title}</Link></td>
                <td>{item.description}</td>
                <td style={{'backgroundColor': _cat.length > 0 ? _cat[0].color: ""}}>{_cat.length > 0 ? _cat[0].name : 'unknown'}</td>
            </tr>
        )
    })
    return (
        
        <Switch>
            <Route path={props.match.url + "/templates/:template"} render={props => <Template reload={reload} setReload={setReload} {...props} categories={categories} />} />
            <Route path={props.match.url + "/:activity"} render={(props) => <Activity reload={reload} setReload={setReload} {...props} />} />
                    
            <React.Fragment>
            <Nav tabs>
                <NavItem>
                    <NavLink className={classnames({ active: activeTab === 'templates' })}
                        onClick={() => { toggleTab('templates'); }}>
                        Templates
                        </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink className={classnames({ active: activeTab === 'activities' })}
                        onClick={() => { toggleTab('activities'); }}>
                        Activities
                        </NavLink>
                </NavItem>
            </Nav>
            <TabContent activeTab={activeTab}>
                <TabPane tabId="templates">
                <Loader loading={loading}><Templates {...props} categories={categories} tasks={tableData} url={props.match.url} /></Loader>
                </TabPane>
                <TabPane tabId="activities">
                <div className="heading-wrapper d-flex justify-content-between align-items-center">
                    <p className="display-4">Activities</p>
                    <Button onClick={() => setShowForm(true)} color="outline-info">Add</Button>

                </div>
                <div className="add-form-wrapper">
                    <ActivityForm {...props} reload={reload} setReload={setReload} types={['daily', 'challenge', 'normal', 'weekly']} categories={categories} hidden={!showForm} />

                </div>
                {loading ? null : <ActivityTable url={props.match.url} categories={categories} data={tableData} />}
                </TabPane>
            </TabContent>
            </React.Fragment>

            {/* <ActivityTable data={TABLEDATA}/> */}
        </Switch>
    );
}

export default Activities;
import React from 'react';
import { Nav, NavItem, NavLink, Navbar, NavbarBrand } from 'reactstrap';

const style = {
    
    menuTitle: {
        padding: '5px 0 0 5px',
       
    },

}

const MyNav = (props) => {
    return (
 
        <Nav style={{ "marginLeft": "-15px",
        "marginRight": "-15px", "height": "100vh"}}className={props.class} vertical>
            <p style={style.menuTitle}>Menu</p>
            <NavItem><NavLink href="/companies">Companies</NavLink></NavItem>
            <NavItem><NavLink href="/activities">Activities</NavLink></NavItem>
            <NavItem><NavLink href="/categories">Categories</NavLink></NavItem>
            <NavItem><NavLink href="/users">Users</NavLink></NavItem>
            <NavItem><NavLink href="/reports">Reports</NavLink></NavItem>
            <NavItem><NavLink href="/vouchers">Vouchers</NavLink></NavItem>
        </Nav>
        
    );
}

export default MyNav;




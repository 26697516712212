import React, { useEffect, useState } from 'react';
import { Link, Route, Switch } from 'react-router-dom';
import superApi from '../../Api/Api';
import { Table, Button } from 'reactstrap';
import User from './User/User';

const Users = (props) => {

    const [tableData, setTableData] = useState([])
    const loadAll = () => {
        superApi.get('/account?'+new Date().getTime()).then((response) => {
            setTableData(response.data._items);
        })
    }
    useEffect(() => {
        superApi.get('/account?page=15&max_results=10').then((response) => {
            setTableData(response.data._items);
        })
    }, [])

    const TABLEDATA = tableData.map(item => {
        return (
            <tr key={item._id}>
                <td><Link to={props.match.url + "/" + item._id}>{item.username}</Link></td>
                <td>{item.company}</td>
            </tr>
        )
    })

    return (
        <Switch>
            <Route path={props.match.url + "/:user"} render={(props) => <User {...props} />} />
            <React.Fragment>
            <p className="display-4">Users</p>
                <Table>
                    <thead>
                        <tr>
                            <th>Username</th>
                            <th>Company</th>
                        </tr>
                    </thead>
                    <tbody>
                        {TABLEDATA}
                    </tbody>
                </Table>
                <Button onClick={loadAll}>Load All</Button>
            </React.Fragment>
        </Switch>
        
     );
}

export default Users;
 

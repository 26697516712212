import React, { useState, useEffect } from 'react';
import superApi from '../../Api/Api';
import { Table, Button } from 'reactstrap';
import { Link, Switch, Route } from 'react-router-dom';
import DataTable from 'react-data-table-component'

const Vouchers = (props) => {
    const [tableData, setTableData] = useState([]);
    const [rawData, setRawData] = useState([]);
    const [showForm, setShowForm] = useState(false);
    const [reload, setReload] = useState(true);
    const [searchFieldValue, setSearchFieldValue] = useState("")

    // const TABLEDATA = tableData.map(item => {
    //     return (
    //         <tr key={item._id}>
    //             <td><Link to={props.match.url + "/" + item.code}>{item.code}</Link></td>
    //             <td>{item.type}</td>
    //             <td>{item.value}</td>
    //         </tr>
    //     )
    // })

    // const TABLEDATA = tableData.map()

    const rowClickHandler = (row) => {
        window.location.href = props.match.url + '/' + row.code
    }

    const onChangeHandler = (value) => {
        setSearchFieldValue(value)
        const prevTableData = rawData.filter((data) => data.code.includes(value))
        setTableData(prevTableData)

    }

    const columns = [
        {
            name: 'Code',
            selector: 'code',
            sortable: true,
        },
        {
            name: 'Type',
            selector: 'type',
            sortable: true,
        },
        {
            name: 'Value',
            selector: 'value',
            sortable: true,
        },
    ];

    useEffect(() => {
        setShowForm(false)
        superApi.get('/voucher').then((response) => {
            setRawData(response.data._items);
            setTableData(response.data._items);
        })
    }, [reload])

    return (
        <React.Fragment>
            <div className="heading-wrapper d-flex justify-content-between align-items-center">
                <p className="display-4">Vouchers</p>
                <Button onClick={() => setShowForm(true)} color="outline-info">Generate</Button>

            </div>

            <input placeholder="Search" name="search" onChange={(e) => onChangeHandler(e.target.value)} value={searchFieldValue} />
            <DataTable
             
                columns={columns}
                data={tableData}
                defaultSortField="code"
                keyField="_id"
                onRowClicked={(row) => rowClickHandler(row)}
                pagination={true}
            />
       
        </React.Fragment>
    );
}

export default Vouchers;
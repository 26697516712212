import React, { useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { Button, Col } from 'reactstrap';
import DatePicker from "react-datepicker";
import superApi from '../Api/Api';


 
import "react-datepicker/dist/react-datepicker.css";

const Checkbox = ({ field, type }) => {
    return (
      <label>
        {/* remove {...field} to see changes not propagated */}
        <input {...field} type={type} />
        {field.name}
      </label>
    );
  }

const CompanyForm = (props) => {
    const [startDateValue, setStartDateValue] = useState(null)
    const handleDateChange = date => {
        setStartDateValue(date);
    };

    const submitHandler = (form) => {
        if(!props.edit) {
            superApi.post('company', form).then((response) => {window.alert('Saved'); props.setReload(!props.reload)})
        } else {
            superApi.patch(`company/${props.company._id}`, form).then((response) => {window.alert('Saved'); props.setReload(!props.reload)})
        }
    }

    const generateSlug = (companyName) => {
        const _slug = companyName.split(' ')[0].replace(/\W/g, '').toLowerCase()
        return _slug
    }

    if (props.hidden) {
        return null;
    }
    return ( 
        <Formik
        initialValues={{ 
            public: props.company?.public !== undefined ? props.company?.public : false, 
            company: props.company !== undefined ? props.company.company : '', 
            slug: props.company?.slug !== undefined ? props.company.slug : '',
            startdate: props.company?.startdate !== undefined ? new Date(props.company.startdate) : new Date(), 
        }}
        validate={values => {
            const errors = {};
            // if (!values.username) {
            //     errors.username = 'Please enter something';
            // }
            return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {
            submitHandler(values);
            setSubmitting(false);
        }}
    >
        {({ isSubmitting, values, setFieldValue }) => (
            <Col md="6">
                <p className="lead">{props.edit ? "Edit" : "Add New"}</p>
            <Form className="add-form">
                <div className="form-group">
                    
                    <Field required="required" className="form-control" type="text" name="company" placeholder="Company Name" />
                    <ErrorMessage name="company" component="div" />
                </div>
                <div className="form-group">
                    <Field required="required" onFocus={(e) => setFieldValue('slug', e.target.placeholder)} className="form-control" type="text" name="slug" placeholder={values['company'] ? generateSlug(values['company']) : 'SLUG'} />
                    <ErrorMessage name="slug" component="div" />
                </div>
                <div className="form-group">
                    <DatePicker name="startdate" selected={values.startdate} onChange={date => setFieldValue('startdate', date)} showTimeSelect dropdownMode="select" dateFormat="dd/MM/yyyy HH:mm"  />
                    {/* <Field className="form-control" type="text" name="startdate" placeholder="Date / Time"/> */}
                    <ErrorMessage name="startdate" component="div" />
                </div>
                <div className="form-group">
                <Field name="public" type="checkbox" component={Checkbox} />
                    {/* <Field className="form-control" type="text" name="startdate" placeholder="Date / Time"/> */}
                    <ErrorMessage name="public" component="div" />
                </div>
                <Button disabled={isSubmitting} color="info" type="submit">{props.edit ? "Update" : "Submit"}</Button>
            </Form>
            </Col>
        )}
    </Formik>

     );
}
 
export default CompanyForm;
import React, { useEffect, useState } from 'react';
import superApi from '../../../Api/Api';
import Activities from './Activities/Activities';
import Contenders from './Contenders/Contenders';
import { TabContent, TabPane, Nav, NavItem, NavLink, Button } from 'reactstrap';
import classnames from 'classnames';
import CompanyForm from '../../../Forms/CompanyForm';
import Detail from './Detail/Detail';
import NewPointsTable from '../../../Components/PointsTable/PointsTable'
import Leaderboard from '../../../Components/Leaderboard/Leaderboard'

const Company = (props) => {
    const [company, setCompany] = useState({});
    const [teams, setTeams] = useState([])
    const [loading, setLoading] = useState(true);
    const [activeTab, setActiveTab] = useState('activities');
    const [tasks, setTasks] = useState({});
    const [contenders, setContenders] = useState([]);
    const [reload, setReload] = useState(true);
    

    const toggleTab = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    }

    const editCompanyHandler = () => {
        props.setShowForm(true)
    }

    const deleteCompanyHandler = () => {
        if(window.confirm('This will PERMANENTLY DELETE ' + company.company)) {

            superApi.delete('company' + '/' + company._id).then(response => {
                props.setReload(!props.reload)
                props.history.goBack()
                
            })
        } else {
        }
    }

    useEffect(() => {
        setLoading(true)
        superApi.get('/company/' + props.match.params.company + '?timestamp=' + new Date().getTime()).then((response) => {
            setCompany(response.data);


            superApi.get('/team?where={"company": "' + response.data._id + '"}&timestamp=' + new Date().getTime()).then(response => {
                setTeams(response.data._items);
                
            }).then(response => {
                superApi.get('account?where={"company": "' + props.match.params.company + '"}&timestamp=' + new Date().getTime()).then(response => {
                    setContenders(response.data._items)
                    setLoading(false);
                })
            })

        })
    }, [reload])


    useEffect(() => {
        if (Object.entries(company).length > 0) {
            let _taskMap = {}
            let promises = []
            new Promise((resolve) => {
                
                let tasks = company.tasks
                tasks.sort((a,b) => a.task > b.task ? 1 : -1).forEach(task => {
                    let p = superApi.get('/task/' + task.task + '?timestamp='+ new Date().getTime()).then((response) => {
                        if (task.hidden) {
                            response.data['hidden'] = task.hidden
                        }
                        response.data['template'] = task.template
                        _taskMap[task.task] = response.data
                        
                    }).catch(err => null);
                    promises.push(p);

                })
                resolve(Promise.all(promises));
                //
            }).then((response) => {
                const _tasks = Object.entries(_taskMap);
                setTasks(_tasks)
                window.setTimeout(() => setLoading(false), 600);
            });
        }
    }, [company])



    return (
        <React.Fragment>
            <p className="display-4">{company.company}</p>
           {/* <Button color="danger" onClick={() => editCompanyHandler()} >Edit</Button> */}
            <Nav tabs>
            <NavItem>
                    <NavLink className={classnames({ active: activeTab === 'detail' })}
                        onClick={() => { toggleTab('detail'); }}>
                        Company
                        </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink className={classnames({ active: activeTab === 'contenders' })}
                        onClick={() => { toggleTab('contenders'); }}>
                        Contenders
                        </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink className={classnames({ active: activeTab === 'activities' })}
                        onClick={() => { toggleTab('activities'); }}>
                        Activities
                        </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink className={classnames({ active: activeTab === 'points' })}
                        onClick={() => { toggleTab('points'); }}>
                        Points Table
                        </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink className={classnames({ active: activeTab === 'leaderboard' })}
                        onClick={() => { toggleTab('leaderboard'); }}>
                        Leaderboard
                        </NavLink>
                </NavItem>
            </Nav>
            <TabContent activeTab={activeTab}>
            <TabPane tabId="detail">
                    <div className="add-form-wrapper">
                    <CompanyForm edit company={company} reload={reload} setReload={setReload} hidden={company.company === undefined} />

                    </div>
                    {<Detail reload={reload} setReload={setReload} company={company} url={props.match.url} />}
                </TabPane>
                <TabPane tabId="contenders">
                    {<Contenders reload={reload} setReload={setReload} url={props.match.url} teams={teams} contenders={contenders} />}
                </TabPane>
                <TabPane tabId="activities">
                    {<Activities templates={props.templates} reload={reload} setReload={setReload} setLoading={setLoading} company={company} url={props.match.url} activities={tasks} />}
                </TabPane>
                <TabPane tabId="points">
                {!loading ? <NewPointsTable where={`{"company": "${company._id}"}`} /> : null}
                    {/* {loading ? null : <PointsTable contenders={contenders} reload={reload} setReload={setReload} company={company} url={props.match.url} activities={tasks} />} */}
                </TabPane>
                <TabPane tabId="leaderboard">
                    <Leaderboard />
                </TabPane>
            </TabContent>
            
        </React.Fragment>
    );
}

export default Company;
import React, { useEffect, useState } from 'react';
import superApi from '../../../Api/Api';
import { Col, Button } from 'reactstrap';
import CategoryForm from '../../../Forms/CategoryForm';

const Category = (props) => {
    const [category, setCategory] = useState({})
    const [showForm, setShowForm] = useState(false);
    useEffect(() => {
        
        superApi.get('/category/' + props.match.params.category).then((response) => {
            
            setCategory(response.data);

        })
    }, [])
    return ( 
        <Col md="12">
            <div className="heading-wrapper">
            <p className="display-4">{category.slogan}</p>
                    <Button onClick={() => setShowForm(true)} color="outline-primary">Edit</Button>

                </div>
        
        <p className="lead">{category.name}</p>
        <div style={{backgroundColor: category.color, minHeight: '50px'}}></div>
        <div className="add-form-wrapper">
            <CategoryForm {...props} title="Edit" existing={category} hidden={!showForm} hidden={!showForm}/>

        </div>
        </Col>
     );
}
 
export default Category;
import React, { useEffect, useState } from 'react';
import superApi from '../../../../Api/Api';
import { Button } from 'reactstrap';
import TemplateForm from '../../../../Forms/TemplateForm';
import { Link } from 'react-router-dom';
import './Template.css';

const Template = (props) => {
    const templateId = props.match.params.template;
    const [template, setTemplate] = useState({})
    const [existingTasks, setExistingTasks] = useState([])
    const [tasks, setTasks] = useState([])
    const [loading, setLoading] = useState(true);
    const [reload, setReload] = useState(true)
    const [showForm, setShowForm] = useState(false);
    const [taskFilter, setTaskFilter] = useState("a")
    const [categoryFilter, setCategoryFilter] = useState(null)
    const [showFilter, setShowFilter] = useState(false)
    const [draggedFrom, setDraggedFrom] = useState(null)
    console.log(tasks)
    console.log(existingTasks)
    const deleteTemplateHandler = () => {
        if (window.confirm('This will PERMANENTLY DELETE this template. The Activities won\'t be deleted')) {
            superApi.delete('template/' + template._id).then(response => {
                console.log(response)
                setReload(!reload)
                props.history.goBack()
            })
        }
    }
    useEffect(() => {
        setShowForm(false)
        superApi.get('/template/' + templateId).then((response) => {
            setTemplate(response.data);
            setExistingTasks(response.data.tasks)

        }).then(() => {
            superApi.get('/task').then((response) => {
                setTasks(response.data._items);
                window.setTimeout(setLoading(false), 1000);
            })
        })
    }, [reload])


    if (loading) {
        return (
            <p>Loading</p>
        )
    }
    const AVAILABLE = tasks.filter(task => {

        // if task not in existingTasks


        if (!existingTasks.filter(t => t === task._id).length > 0) {
            if (task.title.toLowerCase().includes(taskFilter.toLowerCase()) || task.description.toLowerCase().includes(taskFilter.toLowerCase())) {
                if (categoryFilter === null) {
                    return task
                }
                if (categoryFilter !== null && task.category === categoryFilter) {
                    return task
                }

            }

        }
    }).map(task => {

        const _catColors = props.categories.filter((cat) => {

            return cat.name === task.category
        })[0]
        const _color = _catColors !== undefined ? _catColors.color : null
        return (


            <li draggable onDragStart={e => dragStartHandler(e, JSON.stringify(task), 'available')} className="availableTask" key={task._id}><div style={{ "backgroundColor": _color, height: '20px', width: '20px' }}></div>{task.title}</li>
        )
    })
    const TASKS = existingTasks.map(task => {
        let _index = null;
        tasks.forEach((t, i) => {
            if (t._id == task) {
                _index = i;

            }
        })

        const _catColors = props.categories.filter((cat) => {

            return cat.name === tasks[_index].category
        })[0]
        const _color = _catColors !== undefined ? _catColors.color : null
        if (categoryFilter !== null && tasks[_index].category === categoryFilter) {
            return (
                <li draggable onDragStart={e => dragStartHandler(e, JSON.stringify(tasks[_index]), 'current')} className="task" key={task}><div style={{ "backgroundColor": _color, height: '20px', width: '20px' }}></div><Link to={"/activities/" + task}>{tasks[_index].title}</Link></li>
            )
        }
        if (categoryFilter === null) {

            return (
                <li draggable onDragStart={e => dragStartHandler(e, JSON.stringify(tasks[_index]), 'current')} className="task" key={task}><div style={{ "backgroundColor": _color, height: '20px', width: '20px' }}></div><Link to={"/activities/" + task}>{tasks[_index].title}</Link></li>
            )
        }
    })

    const dropHandler = (e, to) => {
        const item = JSON.parse(e.dataTransfer.getData("item"))
        const from = draggedFrom
        if (from === to) {
            return false
        }
        if (to === 'available') {
            // append to tasks
            // let _prev = tasks.map(task => {
            //     return task
            // })
            // _prev.push(item)
            // setTasks(_prev)
            const _prevExisting = existingTasks.filter(task => {

                return task !== item._id

            })
            setExistingTasks(_prevExisting)
        }
        if (to === 'current') {
            // append to existingTasks

            let _prev = existingTasks.map(task => task)
            _prev.push(item._id)
            setExistingTasks(_prev)
            // const _prevAvailable = tasks.filter(task => task._id !== item._id)
            // console.log(_prevAvailable)
            // setTasks(_prevAvailable)
        }
    }

    const dragStartHandler = (e, task, from) => {
        setDraggedFrom(from)
        e.dataTransfer.setData("item", task)

    }
    return (
        // <div className="Template">
        //     <div className="heading-wrapper d-flex justify-content-between align-items-center">
        //             <p className="display-4">{template.name}</p>
        //             <Button onClick={() => setShowForm(true)} color="outline-primary">Edit</Button>
        //             <Button onClick={() => deleteTemplateHandler()} color="outline-danger">Delete</Button>
        //         </div>
        //         <div className="add-form-wrapper">
        //         <TemplateForm title="Edit" reload={reload} setReload={setReload} tasks={tasks} template={template} existingTasks={existingTasks} hidden={!showForm} />

        //         </div>


        //     <p className="lead">Included Activities</p>
        // <ul>

        //     {TASKS.length > 0 ? TASKS : <li>None... add some!</li>}
        // </ul>
        // </div>
        <div className="Template">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                    <div className="filterTaskWrapper">
                            <input className="filterTask" placeHolder="Search" onChange={(e) => setTaskFilter(e.target.value)}></input>
                            <Button onClick={() => setShowFilter(!showFilter)} size="sm" outline>{showFilter ? "V" : ">"}</Button>
                        </div>
                        <div style={{ display: showFilter ? 'block' : 'none' }} className="filterTaskButtonWrapper">
                            <Button onClick={() => setCategoryFilter(null)} outline color="info" className="filterTaskButton" size="sm">CLEAR</Button>
                            {props.categories.map(cat => {

                                return (
                                    <Button onClick={() => setCategoryFilter(cat.name)} className="filterTaskButton" size="sm" style={{ backgroundColor: cat.color, borderColor: cat.color }}>{cat.name}</Button>
                                )
                            })}
                        </div>
                    </div>
                </div>
                <div className="row">

                    <div className="col-md-6">


                        <ul className="currentTasks"
                            onDrop={(e) => dropHandler(e, 'current')}
                            onDragOver={(e) => e.preventDefault()}>
                            <p className="lead">Template Activities</p>

                            <div style={{ width: '100%', textAlign: 'center' }}>{TASKS.length < 1 ? "Drag Activities Here" : TASKS}</div>
                        </ul>
                        {/* <div className="add-form-wrapper"> */}

                        {/* </div> */}

                    </div>
                    <div className="col-md-6">
                       
                        <ul className="availableTasks"
                            onDrop={(e) => dropHandler(e, 'available')}
                            onDragOver={(e) => e.preventDefault()}>
                            <p className="lead">Available Activities</p>

                            {AVAILABLE}
                        </ul>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <TemplateForm title="Edit" reload={reload} setReload={setReload} tasks={tasks} template={template} existingTasks={existingTasks} hidden={showForm} />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Template;
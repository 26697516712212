import React from 'react';
import DataTable from 'react-data-table-component'
import { Link } from 'react-router-dom';
// import  Moment from 'react-moment';

const styles = {
    categoryCell: {

        "height": "100%",
        "width": "100%",
        "textTransform": "uppercase",
        "display": "flex",
        "justifyContent": "center",
        "alignItems": "center",
        "color": "white",
        "fontWeight": "bold"
    }
}
const ActivityTable = (props) => {

    const rowClickHandler = (row) => {
        console.log(row)
    }
 
    const columns = [
        {
            name: 'Category',
            selector: 'category',
            sortable: true,
            maxWidth: '150px',
            cell: row => <div style={{ ...styles.categoryCell, "backgroundColor": props.categories.filter(c => c.name === row.category)[0] !== undefined ? props.categories.filter(c => c.name === row.category)[0].color : "" }}>{row.category}</div>
        },
        {
            name: 'Type',
            selector: 'type',
            sortable: true,
            maxWidth: '50px',
        },
        {
            name: 'Title',
            selector: 'title',
            sortable: true,
            maxWidth: '300px',
            cell: row => <Link to={props.url + "/" + row._id}>{row.title}</Link>
        },
        {
            name: 'Description',
            selector: 'description',
            sortable: true,
            maxWidth: '600px',
        },
        {
            name: 'Points',
            selector: 'points',
            sortable: true,
            maxWidth: '50px',
        },
        
    ];

    return (
        <DataTable

            columns={columns}
            data={props.data}
            defaultSortField="title"
            keyField="_id"
            onRowClicked={(row) => rowClickHandler(row)}
            pagination={true}
            noHeader
            dense
        />
    );
}

export default ActivityTable;
import React, { useEffect, useState, useImperativeHandle } from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink, Button } from 'reactstrap';
import superApi from '../../../Api/Api';
import classnames from 'classnames';
import PointsTable from '../../../Components/PointsTable/PointsTable';
import { Formik, Form, Field, ErrorMessage } from 'formik';

const User = (props) => {

    const [user, setUser] = useState(null)
    const [activeTab, setActiveTab] = useState('profile');
    const [reload, setReload] = useState(false)
    const [companies, setCompanies] = useState([])


    const toggleTab = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    }

    const submitHandler = (form) => {
        console.log(user._id)
        superApi.patch('account' + '/' + user._id, form).then(response => {
            // console.log(response)
            setReload(!reload)
            // props.history.goBack()
        })
        console.log(form)
    }

    useEffect(() => {
        superApi.get('/company/').then(response => {
            setCompanies(response.data._items)
        })
    },[])

    const COMPANIES = companies.map(item => {
        return <option value={item._id}>{item.company}</option>
    })

    useEffect(() => {

    }, [companies])

    useEffect(() => {
        superApi.get('/account/' + props.match.params.user)
            .then(response => {
                // console.log(response.data)
                superApi.get('/company/' + response.data.company)
                    .then(r => {
                        // console.log(r.data)
                        response.data['company'] = r.data.company
                        setUser(response.data)
                    })
            })
    }, [reload])

    const handleDeleteUser = () => {
        const payload = {
            "active": !user.active
        }
        superApi.patch('/account/' + user._id, payload)
            .then(response => {
                setReload(!reload)
            })
    }

    if (user !== null) {
        let fullname = user.username
        try {

            fullname = user.profile.first_name + ' ' + user.profile.last_name
        } catch {

        }

        // console.log(user.active)
        return (
            <div className="User">
                <div className="heading-wrapper d-flex justify-content-between align-items-center">
                    <p className="display-4">{fullname}</p>
                    <Button onClick={handleDeleteUser} color={user.active || user.active === undefined ? "success" : "danger"}>{user.active || user.active === undefined ? "ACTIVE" : "DEACTIVATED"}</Button>

                </div>
                <Nav tabs>
                    <NavItem>
                        <NavLink className={classnames({ active: activeTab === 'detail' })}
                            onClick={() => { toggleTab('profile'); }}>
                            Profile
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink className={classnames({ active: activeTab === 'points' })}
                            onClick={() => { toggleTab('points'); }}>
                            Points
                        </NavLink>
                    </NavItem>
                </Nav>
                <TabContent activeTab={activeTab}>

                    <TabPane tabId="profile">
                        <ul>
                            <li><h5>Email</h5> {user.profile.email}</li>
                            <hr />
                            <li><h5>Name</h5> {fullname === user.username ? "None" : fullname}</li>
                            <hr />
                            <li><h5>Region</h5> {user.profile.region || "None"}</li>
                            <hr />
                            <li><h5>Company</h5> {user.company || "None"}</li>
                            <hr />
                        </ul>
                        <Formik
                            initialValues={{
                                
                                company: user !== null ? user.company : '',
                                
                                
                            }}
                            validate={values => {
                                const errors = {};
                                // if (!values.username) {
                                //     errors.username = 'Please enter something';
                                // }
                                return errors;
                            }}
                            onSubmit={(values, { setSubmitting }) => {
                                submitHandler(values);
                                setSubmitting(false);
                            }}
                        >
                        {({ isSubmitting, values, setFieldValue }) => (
                        <Form className="edit-user">
                            <div className="form-group">

                                <Field as="select" required="required" className="form-control" type="text" name="company" placeholder="Company Name">
                                <option>-- Select New Company --</option>
                                {COMPANIES}
                                </Field>
                                <ErrorMessage name="company" component="div" />
                            </div>
                            <Button disabled={isSubmitting} color="info" type="submit">Submit</Button>
                        </Form>
                        )}
                        </Formik>
                    </TabPane>
                    <TabPane tabId="points">
                        <PointsTable where={`{"contender": "${user._id}"}`} />
                    </TabPane>
                </TabContent>
            </div>
        )
    }
    return (
        <p>...</p>
    )
}

export default User;
import React from 'react';
import { Route } from 'react-router-dom';
import { Navbar, NavbarBrand } from 'reactstrap';
import MyNav from './Nav/Nav';
import Home from './Home/Home';
import Aux from '../HOC/Aux';
import { Container, Row, Col } from 'reactstrap';
import Activities from './Activities/Activities';
import Companies from './Companies/Companies';
import Categories from './Categories/Categories';
import Vouchers from './Vouchers/Vouchers';
import Users from "./Users/Users";

const styles = {
    'leftNav': {
        // "backgroundColor": "black",
        "color": "white",
        "paddingTop": "20px",
        "height": "100vh",
        "marginLeft": "-15px",
        "marginRight": "-15px",
        
    },
    'topNav': {
        "width": "100%",
        
    },
    'main': {
        "height": "100vh"
    }
}
const Layout = (props) => {
    return (
        <Aux>
            <Container fluid>
                
                <Row>
                    

                    <Navbar style={styles.topNav} color="info"><NavbarBrand>Admin</NavbarBrand></Navbar>
                    
            </Row>
            <Row style={styles.main}>
                    <Col md="2">

                        <MyNav style={styles.leftNav} class="navbar-light bg-secondary"  />
                    </Col>
                    
                    <Col md="10">
                        <Route path="/" exact render={() => <Home />} />
                        <Route path="/companies" render={(props) => <Companies {...props} />} />
                        <Route path="/activities" render={(props) => <Activities {...props} />} />
                        <Route path="/categories" render={(props) => <Categories {...props} />} />
                        <Route path="/users" render={(props) => <Users {...props} />} />
                        <Route path="/vouchers" render={(props) => <Vouchers {...props} />} />
                    </Col>

                </Row>
            </Container>
        </Aux>
    );
}

export default Layout;
import React from 'react';

const Loader = (props) => {
    if (props.loading) {
        return (
            <p className="lead">Loading...</p>
        );
    }

    return props.children;

}

export default Loader;
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Table, Button } from 'reactstrap';
import AddActivityTemplateForm from '../../../../Forms/AddActivityTemplateForm';
import superApi from '../../../../Api/Api';

const Activities = (props) => {


    
    useEffect(() => {
        superApi.get('template').then(response => {
            setTemplates(response.data._items)
        })
    },[])
    
    const [showForm, setShowForm] = useState(false);
    const [templates, setTemplates] = useState([]);
    
    const toggleActivityHandler = (activityId) => {
        props.setLoading(true);
        let existingActivities = props.company.tasks;
        const tasks = existingActivities.map((task) => {
            if (task.task === activityId) {
                task.hidden = !task.hidden
                return task
            }
            
            return task
        })
        const _payload = {
            "tasks": tasks,
        }
        superApi.patch('company/'+props.company._id, _payload).then(response => {
            props.setReload(!props.reload);
        })
    }

    let _tasks = []
    try {
        props.activities.sort((a,b) => a[0] > b[0] ? 1 : -1).map(item => {
            const template = templates.filter((t) => t._id === item[1].template)
            _tasks.push((
                <tr key={item[0]}>
                <td><Link to={'/activities/' + item[0]}>{item[1].title}</Link></td>
                <td>{item[1].category}</td>
                <td>{template[0] !== undefined ? template[0].name : null}</td>
                <td>{item[1].type}</td>
                <td>{item[1].points}</td>
                <td><Button color="outline-danger" onClick={() => toggleActivityHandler(item[0])}>{item[1].hidden ? 'SHOW' : 'HIDE'}</Button></td>
            
            </tr>
        ))
    })
} catch {

}

    
        return (
            <React.Fragment>
            <div className="heading-wrapper d-flex justify-content-between align-items-center">
                    <p className="lead">Activities</p>
                    <Button onClick={() => setShowForm(true)} color="outline-primary">Apply Template</Button>

                </div>
                <div className="add-form-wrapper">
                <AddActivityTemplateForm setLoading={props.setLoading} templates={templates} title="Add" company={props.company._id} hidden={!showForm} />

                </div>
            <Table>
                <thead>
                    <tr>
                        <th>Task</th>
                        <th>Category</th>
                        <th>Template</th>
                        <th>Type</th>
                        <th>Points</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {_tasks}
                </tbody>
            </Table>
            </React.Fragment>

        );
    }


export default Activities;
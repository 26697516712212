import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { Button } from 'reactstrap';

import './LoginForm.css';

const LoginForm = (props) => {
    
    return (

        <Formik
            initialValues={{ username: '', password: '' }}
            validate={values => {
                const errors = {};
                if (!values.username) {
                    errors.username = 'Please enter something';
                }
                return errors;
            }}
            onSubmit={(values, { setSubmitting }) => {
                props.submitHandler(values);
                setSubmitting(false);
            }}
        >

            <Form className="loginForm">
                <div className="form-group">
                    
                    <Field className="form-control" type="text" name="username" placeholder="username" />
                    <ErrorMessage name="email" component="div" />
                </div>
                <div className="form-group">
                    <Field className="form-control" type="password" name="password" placeholder="password"/>
                    <ErrorMessage name="password" component="div" />
                </div>
                <Button color="info" type="submit">Login</Button>
            </Form>
        </Formik>
    )
}


export { LoginForm };
import React, { useState, useEffect } from 'react';
import { Button, Input } from 'reactstrap';
import { Link } from 'react-router-dom';
import superApi from '../../../../Api/Api';
import Moment from 'react-moment';

const styles = {
    'regionOption': {
        'display': 'flex',
        'justifyContent': 'space-between',
        'width': '200px',
        'marginTop': '10px',
    }
}

const RegionOptionField = (props) => {
    
    return (
        <div style={
            {'display': 'flex', 
            'flexDirection': 'row', 
            'justifyContent': 'space-between',
            'margin': '10px 0'
            }}>
            <Input style={{'width': '50%'}} id="new-option" type="text" name="option" placeholder="London Office" />
            <Button onClick={(e) => props.addOptionHandler(document.getElementById("new-option").value)}>Add</Button>
        </div>
    )
}

const Detail = (props) => {

    const [currentOptions, setCurrentOptions] = useState([]);
    
    const addOptionHandler = (option, remove=false) => {
        let options = []
        if (currentOptions.length == 0) {
            options = props.company.regions
        } else {
            options = currentOptions;
        }
        
        if(options === undefined) {
            options = [];
        }
        if(remove) {
            let _options = options.filter(o => o != option)
            options = _options
        } else {
            options.push(option)
        }
        setCurrentOptions(options)
        const data = {"regions": options}
    
        superApi.patch('company/'+props.company._id, data).then(response => {
            props.setReload(!props.reload);
        })
    }
    let REGIONOPTIONS = []
    if (currentOptions.length == 0) {
        if (props.company.regions === undefined) {
            // do fuck all
        } else {

            REGIONOPTIONS = props.company.regions.map(option => {
                return (
                    <li key={option} value={option} style={styles.regionOption}>{option}<button className="btn-circle btn-outline-primary btn-circle-sm" onClick={() => addOptionHandler(option, true)} >-</button></li>
                    )
                })
            }
    } else {

        REGIONOPTIONS = currentOptions.map(option => {
            return (
                <li key={option} value={option} style={styles.regionOption}>{option}<button className="btn-circle btn-outline-primary btn-circle-sm" onClick={() => addOptionHandler(option, true)} >-</button></li>
                )
            })
        }

    const [showOptionField, setShowOptionField] = useState(false)
    
    return ( 
        <div className="detail">
            {/* <h5>Start Date</h5>
            <div className="starttime-wrapper d-flex align-items-center "><p className="lead mr-2 mb-0"><Moment date={props.company.startdate} format="DD MMMM YYYY \a\t HH:mm A"/></p><Link to="#">Change</Link></div> */}
            <hr />
            <div className="heading-wrapper d-flex justify-content-between align-items-center">
                    <h5>Region Profile Options</h5>
                    <Button onClick={() => setShowOptionField(true)} color="outline-primary">Add Option</Button>

                </div>
                {showOptionField ? <RegionOptionField addOptionHandler={addOptionHandler} reload={props.reload} setReload={props.setReload} {...props} /> : null}
                <ul className="option-list">
                    {REGIONOPTIONS}
                </ul>


        </div>
     );
}
 
export default Detail;
import React, { useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { Button, Col, Label } from 'reactstrap';
import DatePicker from "react-datepicker";
import superApi from '../Api/Api';




const MyForm = (props) => {
    let existing = true;
    if(props.existing === undefined) {
        existing = false;
    } 

    const [startDateValue, setStartDateValue] = useState(null)
    const handleDateChange = date => {
        setStartDateValue(date);
    };

    const submitHandler = (form) => {
        console.log(form);
        if (props.existing) {
            superApi.patch('task' + '/' + props.existing._id, form).then(response => {
                console.log(response)
                props.setReload(!props.reload)
                props.history.goBack()
            })
        } else {

            superApi.post('task', form).then((response) => { 
                props.setReload(!props.reload)
                
            })
        }
    }
    

    const CATEGORIES = props.categories.map(item => {
        return <option value={item.name}>{item.slogan}</option>
    })

    const TYPES = props.types.map(item => {
        return <option value={item}>{item}</option>
    })

    if (props.hidden) {
        return null;
    }
    return (
        <Formik
            initialValues={{
                category: existing ? props.existing.category : "notice",
                type: existing ? props.existing.type : 'daily',
                title: existing ? props.existing.title : '',
                description: existing ? props.existing.description : '',
                delay: existing ? props.existing.delay : 0,
                points: existing ? props.existing.points : 1,
                url: existing ? props.existing.url : '#',
                bonus_streak: existing ? props.existing.bonus_streak : '5',
                points: existing ? props.existing.points : '1',
            }}
            validate={values => {
                const errors = {};
                // if (!values.username) {
                //     errors.username = 'Please enter something';
                // }
                if (values.type === 'challenge' || values.type === 'daily') {
                    const streakInt = parseInt(values.bonus_streak)
                    if (values.bonus_streak == "" || streakInt < 2 || streakInt > 7) {
                        errors.bonus_streak = 'Enter a number between 2 and 7'
                        console.log(errors)
                    }

                }
                return errors;
            }}
            onSubmit={(values, { setSubmitting }) => {
                submitHandler(values);
                setSubmitting(false);
            }}
        >
            {({ isSubmitting, values, setFieldValue }) => (
                <Col md="6">
                    <p className="lead">{props.title || "Add New"}</p>
                    <Form className="add-form">
                    <div className="form-group">
                            <Field className="form-control" setFieldValue as="select" name="category">
                                <option>-- Select Category --</option>
                                {CATEGORIES}
                            </Field>
                            <ErrorMessage name="category" component="div" />
                        </div>
                        <div className="form-group">
                        <Label for="type">Type</Label>
                            <Field className="form-control" setFieldValue as="select" name="type">
                                
                                {TYPES}
                            </Field>
                            <ErrorMessage name="type" component="div" />
                        </div>
                        <div className="form-group">
                        <Label for="bonus_streak">Bonus Streak Count</Label>
                            <Field className="form-control" as="input" name="bonus_streak" placeholder="Streak required for bonus" />
                            <ErrorMessage name="bonus_streak" component="div" />
                        </div>
                        <div className="form-group">
                        <Label for="title">Title</Label>
                            <Field className="form-control" as="input" name="title" placeholder="Short title" />
                            <ErrorMessage name="title" component="div" />
                        </div>
                        <div className="form-group">
                            <Label for="description">Description</Label>
                            <Field className="form-control" as="input" name="description" placeholder="Long Description..."/>
                            <ErrorMessage name="description" component="div" />
                        </div>
                        <div className="form-group">
                        <Label for="url">Activiity Info URL</Label>
                            <Field className="form-control" as="input" name="url" placeholder="www.google.com"/>
                            <ErrorMessage name="url" component="div" />
                        </div>
                        <div className="form-group">
                        <Label for="points">Allocated Points</Label>
                            <Field className="form-control" as="input" name="points" placeholder="1"/>
                            <ErrorMessage name="points" component="div" />
                        </div>

                        <Button disabled={isSubmitting} color="info" type="submit">Submit</Button>
                    </Form>
                </Col>
            )}
        </Formik>

    );
}

export default MyForm;
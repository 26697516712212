import React, { useEffect, useState } from 'react';
import { Table } from 'reactstrap';
import { Link } from 'react-router-dom';
import superApi from '../../../../Api/Api';
import Loader from '../../../../HOC/Loader'

const Contenders = (props) => {
    const [memberMap, setMemberMap] = useState({})
    const [loading, setLoading] = useState(true);
    // const [reload, setReload] = useState(true);
    // const [points, setPoints] = useState([]);

    useEffect(() => {

        // getPoints()

        teamData().then(response => {

            setMemberMap(response)
            setLoading(false);
            

        }).catch(error => console.log(error))
    }, [])


    let _members = {};
    // const getPoints = () => {
    //     superApi.get('points').then(response => {
    //         setPoints(response.data._items);
    //     })
    // }
    let teamData = () => {

        let promises = [];
        props.teams.forEach(item => {
            item.members.forEach(member => {
                let p = superApi.get('/account/' + member+'?timestamp='+new Date().getTime()).then(response => {
                    _members[response.data._id] = {
                        "first_name": response.data.profile.first_name || "Unknown",
                        "last_name": response.data.profile.last_name || "Don't know",
                        "username": response.data.username,
                    }

                        
                    



                });
                // console.log(p)
                promises.push(p);
            });



        });

        // resolve();

        // Promise.all(promises)

        return Promise.all(promises);

    }

    let TABLEDATA;
    if(!loading) {
        
        
        TABLEDATA = props.teams.map(team => {
            let fullname = "No Name"
            const MEMBERS = team.members.map((member) => {
                fullname = ""
                try {
                    // fullname = memberMap[member].first_name + ' ' + memberMap[member].last_name
                    const contender = props.contenders.filter(contender => member === contender._id)[0]
                    if(contender.profile.first_name !== undefined) {

                        fullname = contender.profile.first_name + ' ' + contender.profile.last_name
                    }
                    else {
                        fullname = contender.username
                    }
                } catch{
                   
                }
                return (
                    <li key={member} ><Link to={"/users/"+member}>{fullname}</Link></li>
                )
            })
            fullname ="Loading..."
            try {
                // fullname = memberMap[team.captain].first_name + ' ' + memberMap[team.captain].last_name
            } catch{
                
            }
            return (
                <tr key={team._id}>
                    <td>{team.name}</td>
                    <td>{memberMap[team.captain] != undefined ? memberMap[team.captain].first_name + ' ' + memberMap[team.captain].last_name : 'None'}</td>
                    <td><ul style={{listStyle: 'none'}}>{MEMBERS}</ul></td>
                </tr>
            )
        })

        const LONERS = props.contenders.map(contender => {
            if (contender.team === undefined || contender.team == "") {
                let fullname = contender.username
                if(contender.profile.first_name !== undefined) {

                    fullname = contender.profile.first_name + ' ' + contender.profile.last_name
                }
                return (
                    <tr key={contender._id}>
                    <td>{contender.team || 'None'}</td>
                    <td>None</td>
                    <td>{fullname}<br /><Link to={"/users/"+contender._id}>{contender.username}</Link></td>
                </tr>
                )
            }
        })

        TABLEDATA.push(LONERS)

    }

    return (
        <Loader loading={loading}>
            <Table>
                <thead>
                    <tr>
                        <th>Team Name</th>
                        <th>Captain</th>
                        <th>Members</th>
                    </tr>
                </thead>
                <tbody>
                    {TABLEDATA}
                </tbody>
            </Table>
        </Loader>

    );
}

export default Contenders;